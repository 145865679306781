.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.image {
  max-width: 80%;
}

.label {
  margin: 20px;
  text-align: center;
}
