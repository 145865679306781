@import "../../styles/variables";

.root {
  display: flex;
  align-items: center;
}

.rootRtl {
  flex-direction: row-reverse;
}

.checkbox {
  display: flex;
  align-items: center;
  border-style: solid;
  justify-content: center;
  box-sizing: content-box;
  color: $selection-outline-main-color;

  &.checked {
    color: $selection-outline-secondary-color;
  }

  @media (max-width: 380px) {
    height: 22px !important;
    width: 22px !important;
    min-width: 22px !important;
  }
}

.label {
  margin-left: $small-gutter;
  @media (max-width: 380px) {
    font-size: 18px !important;
  }
}

.labelRtl {
  margin-right: $small-gutter;
}
