@import "../../styles/variables";

.root {
  text-align: center;
  margin: $big-gutter 0;

  @include md {
    text-align: left;
  }
}

.rootRtl {
  text-align: right;

  @include md {
    text-align: left;
  }
}
