@import "../../styles/variables";

.form {
  position: relative;
  margin:0;
  padding:10px 0;
}

.row {
  display: flex;
  margin-top: $big-gutter;
  justify-content: space-between;
}

.rowRtl {
  flex-direction: row-reverse;
}

.bottomRow {
  display: flex;
  justify-content: space-between;

  @media (max-width: $iframe-mobile * 1.3) {
    flex-direction: column-reverse;
  }
}

.input {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%
}
.inputCVVfalse {
  display: flex;
  flex-direction: column;
  flex-basis: 45%;
}

.middleInput {
  margin: 0 $small-gutter * 2;
}

.microLabel input{
  @media (max-width: 275px) {
    font-size: 9px !important;
  }
  @media (max-width: 220px) {
    font-size: 8px !important;
  }
}

.bottomRowWrapper {
  display: flex;
  flex: 1 1 100%;
  align-items: center;
  &:nth-child(2) {
    justify-content: flex-end;
    margin-right: 10px;
    margin-left: 10px;
    @media (max-width: 380px) {
      justify-content: flex-start;
    }
  }
}

.storeCard {
  margin-top: 24px;
}

.buttonWrapper {
  justify-content: center;
  flex-direction: column;
}

.buttons {
  display: flex;
  flex: 1 1 200%;
  flex-direction: column;
}

.button {
  max-width: 140px;
  width: 100%;
  text-align: center;

  @media (max-width: $iframe-mobile) {
    max-width: 120px;
  }
}

.cardForm {
  height: 100%;
  width: 100%;
  border: 0;
  margin-bottom: 24px;
}

.error, .verifyCardText {
  text-align: center;
  @media (max-width: 380px) {
    font-size: 12px !important;
  }
}
.error {
  margin-top: 24px;
}
.verifyCardText {
  margin: 24px 0;
}

.loaderRoot {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  display: flex;
}

.recaptcha {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}