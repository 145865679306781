.container {
    div {
        display: none;
    }
    div:last-child {
        display: contents;
    }
}

.paypalButton div div {
    width: 100% !important;
}