@import "../../styles/variables";

$size: 20px;

.row {
  display: flex;
  align-items: center;
}

.rowRtl {
  flex-direction: row-reverse;
}

.radio {
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  height: $size;
  width: $size;
  border-radius: $size;
  border-width: 1px;
  margin-right: $small-gutter;
  color: $selection-outline-main-color;
  
  &.selected {
    color: $selection-outline-secondary-color;
  }

  @media (max-width: 380px) {
    height: 22px !important;
    width: 22px !important;
    min-width: 22px !important;
  }
}

.radioRtl {
  margin-right: 0;
  margin-left: $small-gutter;
}

.dot {
  border-radius: $size / 2;
  width: $size / 2;
  height: $size / 2;
}
