@import "../../styles/variables";

.weChatWrapper {
  width: 308px;
  max-width: 90%;
  max-height: 70%;
  height: 300px;
}

.payconiqWrapper {
  height: 180px;
  width: 180px;
  min-width: initial;
  min-height: initial;
}

.iframeWrapper {
  display: flex;
  flex-direction: column;
  padding: 16px;
  min-height: initial;
}

.iframeWeChat {
  border-width: 0;
  height: inherit;
}

.iframePayconiq {
  height: 180px;
  width: 180px;
  border-width: 0;
}

.cancel {
  align-self: flex-end;
  margin: $small-gutter;
}

.accept {
  align-self: flex-end;
  margin: $small-gutter;
}

.row {
  display: flex;
  margin-bottom: $small-gutter;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}
