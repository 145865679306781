@import "../../styles/variables";

.root {
  margin: $big-gutter $small-gutter;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.firstRow {
  @extend .row;
  margin-bottom: $big-gutter;
  align-items: center;
}

.manageViewButton {
  margin-bottom: 0!important;
  align-self: flex-end;
}

.rowRtl {
  flex-direction: row-reverse;
}

.linkRoot {
  margin-top: $small-gutter;

  @include lg {
    margin-top: initial;
  }
}

.manageView {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
