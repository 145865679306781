@import "../../styles/variables";
@import "../../styles/mixins";

.recaptchaTerms {
  margin: 8px 0 16px;
  font-size: 12px !important;
  text-align: center;
  a {
    text-decoration: none;
  }
  @media (max-width: 540px) {
    text-align: initial;
  }
  @media (max-width: $iframe-mobile) {
    font-size: 10px !important;
  }
}

.marginBottomRemoved {
  margin-bottom: 0;
}