@import "../../styles/variables";

.loader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
  left: 0;
  background: rgba(0,0,0,0.2);
  height: 100vh;
  top: 0;
  align-items: center;
  z-index: 9999;
}

.container {
  display: flex;
  align-items: center;
  margin: 0 -8px 18px auto;
  width: 100%;
}

.middleInput {
  margin-right: $small-gutter * 2;
  max-width: 120px;
}

.input {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%
}

.button {
  padding: 0 10px;
  align-self: normal;
  @media (max-width: 380px) {
    font-size: 14px !important;
  }
}
