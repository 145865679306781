@import "../../styles/variables";
@import "../../styles/breakpoints";

.input {
  width: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 380px) {
    font-size: 14px !important;
  }
  @media (max-width: 320px) {
    width: 140px;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
  }
}
.isRemoveAvailable {
  @media (max-width: 460px) {
    font-size: 14px !important;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
  }
  @media (max-width: 320px) {
    width: 90px;
    font-size: 12px !important;
  }
  @media (max-width: 300px) {
    width: 60px;
  }
}

.inputRtl {
  flex-direction: row-reverse;
}

.label {
  margin-left: $small-gutter;
}

.labelRtl {
  margin-right: $small-gutter;
}

.img {
  max-width: 30px;
  @media (max-width: 275px) {
    max-width: 20px;
  }
}

.container {
  display: flex;
  align-items: center;
  button {
    @media (max-width: 460px) {
      font-size: 10px !important;
      padding: 5px;
    }
    @media (max-width: 300px) {
      padding: 5px 0;
    }
  }
}

.containerRtl {
  flex-direction: row-reverse;
}

.root {
  justify-content: space-between;
}
