.touchable {
  padding: 0;
  border: none;
  transition: opacity 0.05s linear;
  user-select: none;
  cursor: pointer;
  background: transparent;
  text-align: left;

  &:not([disabled]):active {
    opacity: 0.5;
  }
}
