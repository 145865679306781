@import "../../styles/variables";
@import "../../styles//breakpoints";

.header {
  padding: $big-gutter;
  text-align: center;
  @media (max-width:380px) {
    div {
    font-size: 18px !important;   
    } 
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding: 0 $big-gutter;
}

.columnContainer {
  display: flex;
  padding: $big-gutter;
  @include xs {
    padding: $small-gutter 0;
  }
}

.columnContainerRtl {
  flex-direction: row-reverse;
}

.column {
  flex: 1 1 25%;
  text-align: center;
  @include xs {
    font-size: 14px !important;
  }
  @media (max-width:380px) {
    font-size: 12px !important;
  }
}

.noCards {
  flex: 1 0 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width:380px) {
    div {
      font-size: 12px !important;   
    } 
  }
}