@import '../../styles/variables';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.rootRtl {
  align-items: flex-end;
}

.label {
  margin-bottom: $small-gutter;
  @media (max-width: 380px) {
    font-size: 12px !important;
  }
}

.value {
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  @media (max-width: 380px) {
    font-size: 18px !important;
  }
}
