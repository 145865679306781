@import "../../styles/variables";

.wrapper {
  display: flex;
  margin-bottom: $big-gutter;
  justify-content: space-between;
  flex-wrap: wrap;
}

.wrapperRtl {
  flex-direction: row-reverse;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 0 8px;
}

.containerRtl {
  align-items: flex-end;
}

.root {
  margin: $small-gutter;
  width: 30%;
}

@media screen and (max-width: 480px) {
  .root {
    width: 46%;
  }
}

@media screen and (max-width: 399px) {
  .root {
    width: 100%;
  }
}

.row {
  display: flex;
  align-items: center;
  padding: $small-gutter;
  width: 100%;
  height: 45px;
}

.image {
  max-width: 25px;
}

.label {
  margin-left: 6px;
  @media (max-width: 380px) {
    font-size: 16px !important;
  }
}
