@import "../../styles/variables";

.successPopup {
  display: flex;
  padding: 10px 0;
  background-color: rgba(60, 200, 30, 0.3);
  border-left: 4px solid green;

  .checkbox {
    margin: 10px;
  }
  .cardVerifiedText {
    color: black;
    align-self: center;
  }
}
.modalMode {
  position: absolute;
  left: 0;
  right: 0;
  top: 35px;
}
.nonModalMode {
  margin-left: 10px;
}