@import "../../styles/variables";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.adaptiveModal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @include lg {
    border-radius: $border-radius;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  background: white;
  position: absolute;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.15) 0 5px 10px 4px;

  @include xs {
    top: initial;
    bottom: initial;
    left: initial;
    right: initial;
    max-width: 300px;
    width: 100%;
  }

  @include sm {
    top: initial;
    bottom: initial;
    left: initial;
    right: initial;
    max-width: 500px;
    width: 100%;
  }

  @include md {
    max-width: 600px;
  }
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
}
