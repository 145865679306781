.root {
  position: fixed !important;
  left: 400px;
  background: white !important;
  border: 1px solid darkblue !important;
  transform: rotate(180deg);
  transition: transform 0.3s linear;
  z-index: 2;

  @media (max-width: 400px) {
    left: calc(100vw - 13%);
  }
}
