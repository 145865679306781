@import "../../styles/variables";

.root {
  margin: 0 $small-gutter 20px;
}

.radioContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: $small-gutter;
}

.radioContainerRtl {
  flex-direction: row-reverse;
}

.divider {
  margin-bottom: $big-gutter;
}

.row {
  display: flex;
  margin-bottom: $big-gutter;
  margin-left: $big-gutter;
  @media (max-width: 275px) {
    margin-left: 0;
  }
}

.rowRtl {
  flex-direction: row-reverse;
  margin-left: 0;
  margin-right: $big-gutter;
}

.displayInput {
  flex: 1 1 100%;
}

.optionWrapper {
  display: flex;

  & > div {
    margin-left: 6px;
  }
}

.radio {
  @media (max-width: 380px) {
    font-size: 18px !important;
  }
}

.detailsWrapper {
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  @media screen and (max-width: $screen-md-min){
    flex-direction: column;
  }
}

.cardVerifyText {
  text-align: center;
  height: 24px;
  @media (max-width: 380px) {
    font-size: 12px !important;
    height: unset;
    margin-bottom: 5px;
  }
}

.backLabel {
  display: flex;
  align-items: center;
}
.backLabelIcon {
  text-decoration: none !important;
  &::before {
    content: "← ";
    margin-right: 3px;
    font-size: 14px;
  }
}

@media (max-width: 380px) {
  .backLabel {
    font-size: 18px !important;
  }
}