@import "../../styles/variables";

.button {
  border-width: 0;
  border-style: solid;
  transition: opacity 0.05s linear;
  user-select: none;
  cursor: pointer;
  background: transparent;
  padding: 10px;
  text-align: center;
  box-sizing: content-box;

  &:not([disabled]):active {
    opacity: 0.5;
  }

  .icon {
    font-size: $small-gutter*3;
    position: absolute;
    top: 3px;
    left: 7%;
    @media (max-width: 320px) {
      display: none;
    }
  }

  @media (max-width: 380px) {
    font-size: 12px !important;
  }
}
