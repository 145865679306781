.modal {
  top: 20%;
  bottom: 20%;
}

.iframe {
  height: 100%;
  width: 100%;
  border-width: 0
}

.iframeHeader {
  text-align: center;
  margin: 20px 0 10px;
  font-weight: bold;
  color: #00112C !important;
}