@import "../../styles/variables";
@import "../../styles/mixins";

.input {
  width: 100%;
  border-width: 0;
  border-radius: inherit;
  padding: 0;
  background: inherit;
  outline: none;
  @media (max-width: 380px) {
    font-size: 14px !important;
  }
  @media (max-width: 320px) {
    margin: 0 3px !important;
  }
  @media (max-width: 300px) {
    font-size: 11px !important;
  }
  @media (max-width: 210px) {
    font-size: 9px !important;
  }
}

.inputRtl {
  text-align: right;
}

.labelRoot {
  display: flex;
  align-items: center;
  height: 100%;

  & > div {
    color: $placeholder-color;
  }

  @media (max-width: $iframe-mobile) {
    padding: 0 !important;
  }
}

.icon {
  margin-left: $small-gutter;
}

.shadow {
  display: flex;
  align-items: center;
  box-shadow: 0 2px 8px 4px #e8e8e8;
}

.container {
  overflow: hidden;
  box-sizing: content-box;
  justify-content: center;
}

.containerWrapper {
  position: relative;
}

.containerFloating {
  & > div {
    top: 0;
    transform: translateY(45%);
  }
}

.inputWrapper {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
}

.afterIconWrapper {
  margin-right: $small-gutter;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

.afterIconWrapper_floated {
  height: 100%;
}

.placeholder {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  @include transition(all 0.2s);
  color: $placeholder-color;
}

.activePlaceholder {
  top: 0;
  left: 0;
  transform-origin: left;
  transform: translateY(-70%) scale(0.8);

  img {
    height: 13px;
  }
}

.positionUnset {
  position: unset;
}

.beforeIcon {
  position: relative;

  img {
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
  }
}
