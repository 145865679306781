.root {
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: plain;
    -apple-pay-button-style: "black";
}
.applePayModal {
    display: flex;
    flex-direction: column;
    min-height: initial;
    padding: 16px;
    .applePayModalText {
        margin-top: 8px;
        padding-bottom: 22px;
        text-align: center;
    }
    button:last-child {
        width: 55px;
        align-self: center;
        margin-top: 22px;
    }
}

.applepayWrapper {
    width: 100%;
    position: relative;
    cursor: pointer;
    &:hover .applepayOverlay {
        display: block;
    }
    .applepayOverlay {
        display: none;
        pointer-events: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(160, 160, 160, 0.4);
      }
}