@import "../../styles/variables";

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: $small-gutter * 2;
}

.rootRtl {
  align-items: flex-end;
}

.label {
  margin-bottom: $small-gutter;
  @media (max-width: 380px) {
    font-size: 12px !important;
  }
}

.input {
  max-width: 200px;
}
