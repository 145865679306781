@import "../../styles/variables";

$image-size: 35px;

.wrapper {
  display: block;
  margin: 0;

  div {
    max-width: 100%;
    border-radius: 3px;
    height: 45px;
    margin-bottom: 10px;

    button {
      height: 100%;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  :last-child {
    margin-bottom: 0px;
  }
}

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: $small-gutter * 2 $small-gutter / 2;
  max-height: 55px;
  flex: 0 1 33%;
  max-width: 150px;
  height: 40px;

  @media (max-width: $iframe-mobile) {
    flex: 0 1 100%;
  }
}

.image {
  max-width: 100%;
}

.button {
  display: flex;
  height: 100%;
}

.amazon {
  display: flex;
  justify-content: center;

  img {
    height: 100%;
  }
}

:global {
  #PayPalCheckout {
    width: 100%;

    & > div > div {
      max-width: 100% !important;
    }
  }

  #GooglePayCheckout {
    width: 100%;

    & > div > button {
      min-width: auto;
      width: 100%;
    }
  }
}

.masterpassWrapper {
  width: 100%;
  position: relative;

  &:hover .masterpassOverlay {
    display: block;
  }
  .masterpassOverlay {
    display: none;
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(160, 160, 160, 0.4);
  }
  .masterpassButton {
    background-color: #000000;
    border-radius: 3px;
    cursor: pointer;
    img {
      display: inherit;
      width: 150px;
      height: 100%;
      margin: auto;
    }
  }
}
