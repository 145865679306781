.label {
  text-align: center;
  overflow: hidden;

  &:before,
  &:after {
    content: "";
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  &:before {
    margin-left: -50%;
  }

  &:after {
    margin-right: -50%;
  }
}

.content {
  display: inline;
  padding: 0 10px;
  @media (max-width: 380px) {
    font-size: 18px !important;    
  }
}
