@import 'breakpoints';

$border-radius: 10px;

$small-gutter: 8px;
$big-gutter: 3 * $small-gutter;

$disabled-color: #EBEBE4;
$placeholder-color: #8B8B8B;
$selection-outline-main-color: #808080;
$selection-outline-secondary-color: #000000;
