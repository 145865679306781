.row {
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 8px
}

.root {
  max-height: 400px;
  overflow-y: scroll;
}

.element {
  padding: 8px 0;
}

.label {
  min-width: 70px;
  line-height: 1.8;
}

.text {
  word-break: break-all;
  font-size: 0.6rem !important;
}
