.mywallet-app,
.mywallet-sdk-form {
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
  }

  iframe {
    height: 100%;
    width: 100%;
    border: 0;
  }
}

@font-face {
  font-family: "icomoon";
  src: url("https://wallet-sdk-test.mycheckapp.com/icomoon.woff2")
}

@font-face {
  font-family: "OpenSans-Normal";
  src: url("https://wallet-sdk-test.mycheckapp.com/OpenSans-Normal.woff2")
}

@font-face {
  font-family: "OpenSans-Bold";
  src: url("https://wallet-sdk-test.mycheckapp.com/OpenSans-Bold.woff2")
}

@font-face {
  font-family: "OpenSans-Regular";
  src: url("https://wallet-sdk-test.mycheckapp.com/OpenSans-Regular.woff2")
}

@font-face {
  font-family: "OpenSans-Light";
  src: url("https://wallet-sdk-test.mycheckapp.com/OpenSans-Light.woff2")
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("https://wallet-sdk-test.mycheckapp.com/Helvetica-Medium.woff2")
}

@font-face {
  font-family: "Helvetica Neue-Bold";
  src: url("https://wallet-sdk-test.mycheckapp.com/Helvetica-Bold.woff2")
}

@font-face {
  font-family: "FlightCenterGothic-Regular";
  src: url("https://wallet-sdk-test.mycheckapp.com/FlightCenterGothic-Regular.woff2")
}

@font-face {
  font-family: "AmericanTypewriter";
  src: url("https://wallet-sdk-test.mycheckapp.com/AmericanTypewriter.woff2")
}

@font-face {
  font-family: "Neutraface2Display-Bold";
  src: url("https://wallet-sdk-test.mycheckapp.com/Neutraface2Display-Bold.woff2")
}

@font-face {
  font-family: "Neutraface2Display-Medium";
  src: url("https://wallet-sdk-test.mycheckapp.com/Neutraface2Display-Medium.woff2")
}

@font-face {
  font-family: "ContaxPro45Lig";
  src: url("https://wallet-sdk-test.mycheckapp.com/ContaxPro45Lig.woff2")
}

@font-face {
  font-family: "ContaxPro75Bold";
  src: url("https://wallet-sdk-test.mycheckapp.com/ContaxPro75Bold.woff2")
}

@font-face {
  font-family: "Frutiger-Bold";
  src: url("https://wallet-sdk-test.mycheckapp.com/Frutiger-Bold.woff2")
}

@font-face {
  font-family: "Frutiger-Roman";
  src: url("https://wallet-sdk-test.mycheckapp.com/Frutiger-Roman.woff2")
}

@font-face {
  font-family: "BrandonGrotesque-Regular";
  src: url("https://wallet-sdk-test.mycheckapp.com/BrandonGrotesque-Regular.woff2")
}

@font-face {
  font-family: "BrandonGrotesque-Bold";
  src: url("https://wallet-sdk-test.mycheckapp.com/BrandonGrotesque-Bold.woff2")
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("https://wallet-sdk-test.mycheckapp.com/Roboto-Regular.woff2")
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("https://wallet-sdk-test.mycheckapp.com/Roboto-Medium.woff2")
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("https://wallet-sdk-test.mycheckapp.com/Roboto-Bold.woff2")
}

@font-face {
  font-family: "WEBBoing-Light";
  src: url("https://wallet-sdk-test.mycheckapp.com/WEBBoing-Light.woff2")
}

@font-face {
  font-family: "WEBBoing-Medium";
  src: url("https://wallet-sdk-test.mycheckapp.com/WEBBoing-Medium.woff2")
}

@font-face {
  font-family: "TypeType-Regular";
  src: url("https://wallet-sdk-test.mycheckapp.com/TypeType-Regular.woff2")
}

@font-face {
  font-family: "TradeGothic-Bold";
  src: url("https://wallet-sdk-test.mycheckapp.com/TradeGothic-Bold.woff2")
}

@font-face {
  font-family: "VillageFlex";
  src: url("https://wallet-sdk-test.mycheckapp.com/VillageFlex.woff2")
}

@font-face {
  font-family: "VillageFlexMedium";
  src: url("https://wallet-sdk-test.mycheckapp.com/VillageFlexMedium.woff2")
}

@font-face {
  font-family: "VillageFlex-Light";
  src: url("https://wallet-sdk-test.mycheckapp.com/VillageFlex-Light.woff2")
}

@font-face {
  font-family: "VillageFlex-Bold";
  src: url("https://wallet-sdk-test.mycheckapp.com/VillageFlex-Bold.woff2")
}

@font-face {
  font-family: "Hind-Bold";
  src: url("https://wallet-sdk-test.mycheckapp.com/Hind-Bold.woff2")
}

@font-face {
  font-family: "Hind-Light";
  src: url("https://wallet-sdk-test.mycheckapp.com/Hind-Light.woff2")
}

@font-face {
  font-family: "Hind-Medium";
  src: url("https://wallet-sdk-test.mycheckapp.com/Hind-Medium.woff2")
}

@font-face {
  font-family: "Hind-SemiBold";
  src: url("https://wallet-sdk-test.mycheckapp.com/Hind-SemiBold.woff2")
}

@font-face {
  font-family: "Hind-Regular";
  src: url("https://wallet-sdk-test.mycheckapp.com/Hind-Regular.woff2")
}

@font-face {
  font-family: "SourceSansPro-Black";
  src: url("https://wallet-sdk-test.mycheckapp.com/SourceSansPro-Black.woff2")
}

@font-face {
  font-family: "SourceSansPro-BlackIt";
  src: url("https://wallet-sdk-test.mycheckapp.com/SourceSansPro-BlackIt.woff2")
}

@font-face {
  font-family: "SourceSansPro-Bold";
  src: url("https://wallet-sdk-test.mycheckapp.com/SourceSansPro-Bold.woff2")
}

@font-face {
  font-family: "SourceSansPro-BoldIt";
  src: url("https://wallet-sdk-test.mycheckapp.com/SourceSansPro-BoldIt.woff2")
}

@font-face {
  font-family: "SourceSansPro-ExtraLight";
  src: url("https://wallet-sdk-test.mycheckapp.com/SourceSansPro-ExtraLight.woff2")
}

@font-face {
  font-family: "SourceSansPro-ExtraLightIt";
  src: url("https://wallet-sdk-test.mycheckapp.com/SourceSansPro-ExtraLightIt.woff2")
}

@font-face {
  font-family: "SourceSansPro-It";
  src: url("https://wallet-sdk-test.mycheckapp.com/SourceSansPro-It.woff2")
}

@font-face {
  font-family: "SourceSansPro-Light";
  src: url("https://wallet-sdk-test.mycheckapp.com/SourceSansPro-Light.woff2")
}

@font-face {
  font-family: "SourceSansPro-LightIt";
  src: url("https://wallet-sdk-test.mycheckapp.com/SourceSansPro-LightIt.woff2")
}

@font-face {
  font-family: "SourceSansPro-Regular";
  src: url("https://wallet-sdk-test.mycheckapp.com/SourceSansPro-Regular.woff2")
}

@font-face {
  font-family: "SourceSansPro-Semibold";
  src: url("https://wallet-sdk-test.mycheckapp.com/SourceSansPro-Semibold.woff2")
}

@font-face {
  font-family: "SourceSansPro-SemiboldIt";
  src: url("https://wallet-sdk-test.mycheckapp.com/SourceSansPro-SemiboldIt.woff2")
}

@font-face {
  font-family: "FuturaStd-Bold";
  src: url("https://wallet-sdk-test.mycheckapp.com/FuturaStd-Bold.woff2")
}

@font-face {
  font-family: "FuturaStd-Book";
  src: url("https://wallet-sdk-test.mycheckapp.com/FuturaStd-Book.woff2")
}

@font-face {
  font-family: "FuturaStd-BoldOblique";
  src: url("https://wallet-sdk-test.mycheckapp.com/FuturaStd-BoldOblique.woff2")
}

@font-face {
  font-family: "FuturaStd-BookOblique";
  src: url("https://wallet-sdk-test.mycheckapp.com/FuturaStd-BookOblique.woff2")
}

@font-face {
  font-family: "FuturaStd-Condensed";
  src: url("https://wallet-sdk-test.mycheckapp.com/FuturaStd-Condensed.woff2")
}

@font-face {
  font-family: "FuturaStd-CondensedBold";
  src: url("https://wallet-sdk-test.mycheckapp.com/FuturaStd-CondensedBold.woff2")
}

@font-face {
  font-family: "FuturaStd-CondensedBoldObl";
  src: url("https://wallet-sdk-test.mycheckapp.com/FuturaStd-CondensedBoldObl.woff2")
}

@font-face {
  font-family: "FuturaStd-CondensedExtraBd";
  src: url("https://wallet-sdk-test.mycheckapp.com/FuturaStd-CondensedExtraBd.woff2")
}

@font-face {
  font-family: "FuturaStd-CondensedLight";
  src: url("https://wallet-sdk-test.mycheckapp.com/FuturaStd-CondensedLight.woff2")
}

@font-face {
  font-family: "FuturaStd-CondensedLightObl";
  src: url("https://wallet-sdk-test.mycheckapp.com/FuturaStd-CondensedLightObl.woff2")
}

@font-face {
  font-family: "FuturaStd-CondensedOblique";
  src: url("https://wallet-sdk-test.mycheckapp.com/FuturaStd-CondensedOblique.woff2")
}

@font-face {
  font-family: "FuturaStd-CondExtraBoldObl";
  src: url("https://wallet-sdk-test.mycheckapp.com/FuturaStd-CondExtraBoldObl.woff2")
}

@font-face {
  font-family: "FuturaStd-ExtraBold";
  src: url("https://wallet-sdk-test.mycheckapp.com/FuturaStd-ExtraBold.woff2")
}

@font-face {
  font-family: "FuturaStd-ExtraBoldOblique";
  src: url("https://wallet-sdk-test.mycheckapp.com/FuturaStd-ExtraBoldOblique.woff2")
}

@font-face {
  font-family: "FuturaStd-Heavy";
  src: url("https://wallet-sdk-test.mycheckapp.com/FuturaStd-Heavy.woff2")
}

@font-face {
  font-family: "FuturaStd-HeavyOblique";
  src: url("https://wallet-sdk-test.mycheckapp.com/FuturaStd-HeavyOblique.woff2")
}

@font-face {
  font-family: "FuturaStd-Light";
  src: url("https://wallet-sdk-test.mycheckapp.com/FuturaStd-Light.woff2")
}

@font-face {
  font-family: "FuturaStd-LightOblique";
  src: url("https://wallet-sdk-test.mycheckapp.com/FuturaStd-LightOblique.woff2")
}

@font-face {
  font-family: "FuturaStd-Medium";
  src: url("https://wallet-sdk-test.mycheckapp.com/FuturaStd-Medium.woff2")
}

@font-face {
  font-family: "FuturaStd-MediumOblique";
  src: url("https://wallet-sdk-test.mycheckapp.com/FuturaStd-MediumOblique.woff2")
}

@font-face {
  font-family: "Gotham-Bold";
  src: url("https://wallet-sdk-test.mycheckapp.com/Gotham-Bold.woff2")
}

@font-face {
  font-family: "Gotham-Book";
  src: url("https://wallet-sdk-test.mycheckapp.com/Gotham-Book.woff2")
}

@font-face {
  font-family: "Arquitecta+W00+Book";
  src: url("https://wallet-sdk-test.mycheckapp.com/Arquitecta+W00+Book.woff2")
}

@font-face {
  font-family: "GTAmerica-ExtendedMedium";
  src: url("https://wallet-sdk-test.mycheckapp.com/GTAmerica-ExtendedMedium.woff2")
}

@font-face {
  font-family: "GTAmerica-ExtendedBold";
  src: url("https://wallet-sdk-test.mycheckapp.com/GTAmerica-ExtendedBold.woff2")
}

@font-face {
  font-family: "TTNorms-Light";
  src: url("https://wallet-sdk-test.mycheckapp.com/TTNorms-Light.woff2")
}

@font-face {
  font-family: "TTNorms-Regular";
  src: url("https://wallet-sdk-test.mycheckapp.com/TTNorms-Regular.woff2")
}

@font-face {
  font-family: "TTNorms-Italic";
  src: url("https://wallet-sdk-test.mycheckapp.com/TTNorms-Italic.woff2")
}

@font-face {
  font-family: "TTNorms-Medium";
  src: url("https://wallet-sdk-test.mycheckapp.com/TTNorms-Medium.woff2")
}

@font-face {
  font-family: "TTNorms-MediumItalic";
  src: url("https://wallet-sdk-test.mycheckapp.com/TTNorms-MediumItalic.woff2")
}

@font-face {
  font-family: "TTNorms-Bold";
  src: url("https://wallet-sdk-test.mycheckapp.com/TTNorms-Bold.woff2")
}

@font-face {
  font-family: "TTNorms-ExtraBold";
  src: url("https://wallet-sdk-test.mycheckapp.com/TTNorms-ExtraBold.woff2")
}

@font-face {
  font-family: "FuturaStd-Book";
  src: url("https://wallet-sdk-test.mycheckapp.com/FuturaStd-Book.woff2")
}

@font-face {
  font-family: "FuturaStd-Light";
  src: url("https://wallet-sdk-test.mycheckapp.com/FuturaStd-Light.woff2")
}

@font-face {
  font-family: "AvenirNextLTPro-Bold";
  src: url("https://wallet-sdk-test.mycheckapp.com/AvenirNextLTPro-Bold.woff2")
}

@font-face {
  font-family: "AvenirNextLTPro-Regular";
  src: url("https://wallet-sdk-test.mycheckapp.com/AvenirNextLTPro-Regular.woff2")
}

@font-face {
  font-family: "IvarText-Bold";
  src: url("https://wallet-sdk-test.mycheckapp.com/IvarText-Bold.woff2")
}

@font-face {
  font-family: "IvarText-BoldItalic";
  src: url("https://wallet-sdk-test.mycheckapp.com/IvarText-BoldItalic.woff2")
}

@font-face {
  font-family: "IvarText-Italic";
  src: url("https://wallet-sdk-test.mycheckapp.com/IvarText-Italic.woff2")
}

@font-face {
  font-family: "IvarText-Medium";
  src: url("https://wallet-sdk-test.mycheckapp.com/IvarText-Medium.woff2")
}

@font-face {
  font-family: "IvarText-MediumItalic";
  src: url("https://wallet-sdk-test.mycheckapp.com/IvarText-MediumItalic.woff2")
}

@font-face {
  font-family: "IvarText-Regular";
  src: url("https://wallet-sdk-test.mycheckapp.com/IvarText-Regular.woff2")
}

@font-face {
  font-family: "IvarText-SemiBold";
  src: url("https://wallet-sdk-test.mycheckapp.com/IvarText-SemiBold.woff2")
}

@font-face {
  font-family: "IvarText-SemiBoldItalic";
  src: url("https://wallet-sdk-test.mycheckapp.com/IvarText-SemiBoldItalic.woff2")
}

@font-face {
  font-family: "Univers-Regular";
  src: url("https://wallet-sdk-test.mycheckapp.com/Univers-Regular.woff2")
}

@font-face {
  font-family: "Univers-Bold";
  src: url("https://wallet-sdk-test.mycheckapp.com/Univers-Bold.woff2")
}

.react-tooltip-lite-arrow {
  border-top: none!important;
}

.grecaptcha-badge {
  visibility: hidden;
}