@import "../../styles/variables";

.rowButton {
  display: flex;
  margin-bottom: $big-gutter;
  justify-content: center;
}

.buttonsCollection {
  justify-content: space-between;
  .orLabel {
    align-self: center;
    margin: 0 20px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    .button {
      flex: 0 1 15px;
      width: 80%;
      margin: auto;
    }
    .orLabel {
      margin: 5px;
    }
  }
}
.buttonsCollectionMobile {
  flex-direction: column;
  .button {
    flex: 0 1 15px;
    width: 80%;
    margin: auto;
  }
  .orLabel {
    margin: 5px;
  }
}

.button {
  flex: 0 1 $iframe-mobile;
}

.row {
  display: flex;
  align-items: center;
}

.rowRtl {
  flex-direction: row-reverse;
}

.backdown {
  margin: 10px;
}

.managementModal {
  max-width: 550px;
  border-radius: 8px;
  border-width: 1px;
  .container {
    padding: 10px;
    padding-top: 0;
    margin: 0 8px;

    .acceptedCards , .formWrapp {
      margin-bottom: $small-gutter*3;
    }
    .selectPaymentText {
      padding: $small-gutter 10px;
      margin-bottom: $small-gutter;
    }

    .backdownModalMode {
      margin-top: 60px;
    }

    .backLabel {
      text-align: start;
      display: flex;
      @media (max-width: 380px) {
        font-size: 18px !important;
      }
    }
    .backLabelIcon {
      text-decoration: none;
      &::before {
        content: "← ";
        margin-right: 3px;
        font-size: 14px;
      }
    }
  }
}