@import "../../styles/_variables.scss";

.row {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 8px;
}

.rowRtl {
  flex-direction: row-reverse;
}

.label {
  margin-right: $small-gutter;
}

.acceptedCard {
  width: 20px;
  margin: 3px 3px 3px;

  @include sm {
    width: 25px;
    margin: 4px;
  }
}

.acceptedCardText {
  white-space: nowrap;
  margin-bottom: 5px;
  @media (max-width: 320px) {
    white-space: normal;
  }
}