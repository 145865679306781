.loader {
    border: 6px solid #ddd; /* Light grey */
    border-top: 6px solid #286adb; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1.5s linear infinite;
    display: flex;
    align-self: center;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
